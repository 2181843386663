<template>
  <input type="file" ref="upload" style="display: none;" :accept="accept" name="file" @change="uploadChange" enctype="multipart/form-data" />
  <div class="J-upload-content" v-if="button == ''">
    <div class="J-upload-icon" @click="upload()">
      <span v-if="!isUpload">
        <box-icon name="upload" size="lg" color="#c6cad3"></box-icon>
      </span>
      <span v-else class="check">
        <box-icon name="check" size="lg" color="#03337a"></box-icon>
      </span>
    </div>
    <button class="J-btn J-btn-upload" @click="upload()" type="button">
      <small>{{ title }}</small>
    </button>
  </div>

  <button class="J-btn J-btn-float camera" v-if="button == 'camera'" @click="upload()">
    <box-icon name="camera" type="solid" color="white"></box-icon>
  </button>
</template>

<script>
export default {
  data: () => ({
    isUpload: false,
  }),
  props: {
    title: { required: false, default: "" },
    accept: { required: false, default: "application/pdf" },
    button: { required: false, default: "" },
  },
  methods: {
    upload: async function () {
      this.$refs.upload.click();
    },

    uploadChange: function (e) {
      let pesoMaximo = 5000000;
      const files = e.target.files;
      let sizeFile = files[0].size;
      if (files[0] === undefined) {
        return null;
      }
      if (sizeFile > pesoMaximo) {
        this.$swal({
          position: "top-center",
          icon: "error",
          title: "El tamaño máximo por archivo es de 5mb",
          showConfirmButton: false,
          timer: 3500,
        });
        return null;
      }

      const imageName = files[0].name;
      if (imageName.lastIndexOf(".") <= 0) {
        return null;
      }
      console.log(imageName);
      if (this.button === "camera") {
        if (!/\.(jpg|jpeg|heif|heic|png|bmp|tiff|webp)$/i.test(imageName)) {
          this.$swal({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "El archivo no es .jpg, .jpeg, .heif o .heic",
            showConfirmButton: false,
            timer: 3000,
          });
          return null;
        }
      } else {
        if (!/\.(pdf)$/i.test(imageName)) {
          this.$swal({
            position: "top-end",
            toast: true,
            icon: "error",
            title: "El archivo no es .pdf",
            showConfirmButton: false,
            timer: 3000,
          });
          return null;
        }
      }
      const fileRead = new FileReader();
      fileRead.readAsDataURL(files[0]);
      fileRead.addEventListener("load", () => {
        const base64code = fileRead.result;
        this.isUpload = true;
        setTimeout(() => {
          this.$emit("data", base64code);
        }, 500);
      });
    },
  },
};
</script>
